@import '../styles/variables';
@import '../styles/_user_classes';

@mixin admin-container-wrapper {
	width: 320px;
	top: 50%;
	left: 50%;
	position: relative;
	transform: translate(-50%, -50%);

	button {
		width: 100%;
		height: 35px;
		cursor: pointer;
		border-radius: 3px;
		background-color: grey;
		border-width: 0px;
		font-weight: 500;
		margin-top: 10px;
		margin-bottom: 5px;
	}
}

@mixin admin-container {
	width: 320px;
	background: $light-green;
	position: absolute;
	padding-top: 10px;
	padding-left: 30px;
	padding-right: 30px;
	padding-bottom: 30px;
	border-radius: 4px;
	align-items: center;
	display: flex;
	flex-direction: column;
	user-select: none;
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-o-user-select: none;

	input {
		width: 100%;
		margin-bottom: 2px;
		margin-top: 5px;
		border-radius: 3px;
		height: 30px;
		text-indent: 2px;
		font-size: 15px;
		text-align: left;
	}

	input:focus {
		outline: 0;
	}

	label {
		margin-top: 8px;
	}

	button:focus {
		outline: 0;
	}
}
