@import 'variables';

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: 'PT Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $gray-1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

form {
  section {
    margin-bottom: 30px;

    h1 {
      font-size: 1.3rem;
      background: rgb(0, 0, 0);
      background: rgba(0, 0, 0, 0.1);
      color: rgb(20, 20, 20);
      display: inline-block;
      padding: 10px 15px;
      position: relative;
      left: -15px;
    }
  }

  .row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 18px;

    &:nth-last-child(2) {
      margin-bottom: 26px;
    }
  }

  label {
    display: inline-block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #171717;
  }

  input, textarea, select {
    border-radius: 2px;
    border: 1px solid darkgray;
    padding: 10px 15px;
    width: 100%;
  }

  textarea {
    height: 120px;
  }
}

.btn {
  background: $primary-green;
  border: none;
  color: $gray-1;
  padding: 10px 20px;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  cursor: pointer;

  &:hover {
    background: $light-green;
  }
}

.page {
  padding: 40px 0;
  max-width: $max-page-width;
  margin: 0 auto;

  .wrapper {
    width: 85%;
    margin: 0 auto;
  }
}

.error {
  color: #d91616;
  margin: 3px 0 0 0;

  &.radio {
    margin: 0;
  }
}
.modal {
  font-size: 16px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  margin: auto;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 28px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}
