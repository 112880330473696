.view-container {
	margin-top: 15px;
	margin-bottom: 15px;
	display: flex;
	flex-direction: column-reverse;
	justify-content: space-around;
}

@media all and (min-width: 50em) {
	.view-container {    
		flex-direction: row;
  }
}

.btn-container {
	position: relative;
  display: inline-block;
}

.translates-button {
	padding: 0;
  width: 50px;
  border: 0;
  background-color: cadetblue;
  color: #333;
  cursor: pointer;
  outline: 0;
  font-size: 40px;
}

.drop-btn-title {
  font-size: x-small;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 300px;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
}

.dropdown-ul {
  background-color: white;
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-li {
  padding: 8px 12px;
}

.dropdown-li:hover {
  background-color: rgba(0, 0, 0, 0.14);
  cursor: pointer;
}

.ul-options > li{
  display: inline-block;
  /* You can also add some margins here to make it look prettier */
  zoom:1;
  padding: 1em;
}