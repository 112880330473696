.home.page {
  .wrapper {
    width: 60%;
  }

  p {
    font-size: 1.1rem;
    letter-spacing: 0.01rem;
    text-align: justify;
    span.title {
      font-size: 1.6rem;
    }
  }
}
