/* Colors */
$primary-green: #25646a;
$light-green: #477c81;
$gray-1: #f4f7f6;// Body background
$white-1: #fafafa;// Header text color

/* Dimensions */
$max-page-width: 1140px;

/* Media queries */
$break-sm: 480px;
$break-lg: 800px;
