@import "../styles/variables";

.submit.page {
  .wrapper {
    @media (max-width: $break-lg) {
      width: 90%;
    }
  }

  form {
    width: 75%;
    margin: 0 auto;

    @media (max-width: $break-lg) {
      width: 80%;
    }

    .success {
      color: green;
      font-weight: bold;
    }
    .error {
      color: red;
      font-weight: bold;
    }
    .btn {
      align-self: flex-end;
    }

    .incident-delete-button {
      background-color: red;
      border: none;
      border-radius: 4px;
      margin-bottom: 6px;
      cursor: pointer;
    }

    .row.radio {
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;

      .radio-buttons {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
      }

      label:first-child {
        margin-right: 10px;
      }

      .radio-label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
      }

      input {
        margin-right: 10px;
        min-width: 10px;
      }
    }
  }
}

.mb-8 {
  margin-bottom: 8px;
}

.links-list {
  margin-block-start: 0;
  margin-block-end: 0;
  li:last-child {
    @extend .mb-8;
  }
  &-item {
    &-delete-button {
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }
}

.title-edit {
  text-align: center;
}
